<template>
  <div class="card">
    <div class="card-body">
      <!-- Spline Area chart -->
      <div class="d-flex justify-content-between mb-4">
        <h4 class="card-title">Day to Day Trend</h4>
        <!-- <v-select :options="items" v-model="defaultMonth"></v-select> -->
      </div>
      <VueApexCharts
        class="apex-charts"
        height="350"
        type="area"
        dir="ltr"
        :series="series"
        :options="chartOptions"
        :key="key"
      ></VueApexCharts>
    </div>
  </div>
</template>
<script>
import appConfig from "@/app.config";
import VueApexCharts from "vue-apexcharts";
import filterMixins from "../../../mixins/filterData";
import moment from "moment";
// import vSelect from "vue-select";
// import "vue-select/dist/vue-select.css";

export default {
  props: ["filters"],
  mixins: [filterMixins],
  components: {
    VueApexCharts,
    // 'v-select': vSelect,
  },
  data() {
    return {
      title: "Live Registrations",
      // defaultMonth: '',
      // items: ['Januarary'],
      series: [
        {
          name: "Views",
          data: [],
        },
        {
          name: "Viewers",
          data: [],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
      },
      chartOptions: {
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: "smooth",
          width: 3,
        },
        colors: ["#556ee6", "#34c38f"],
        xaxis: {
          type: "date",
          categories: [],
        },
      },
      key: 0,
      loading: true,
    };
  },
  watch: {
    filters: {
      deep: true,
      handler() {
        this.getDayTrend();
      },
    },
  },
  created() {
    // this.init();
  },
  methods: {
    init() {
      // this.loading = true;
      // this.loading = false;
    },
    getFormatedDate(date, type = "date") {
      if (type == "date") {
        return moment(date).format("DD MMMM");
      } else {
        return moment(date).format("MMMM");
      }
    },
    async getDayTrend() {
      const searchfiltersParams = this.getCleanFilteredData(this.filters);
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/newsletters/monthTrend",
          {
            params: searchfiltersParams,
          }
        );
        this.series[0].data = response.data.month_trend.map(
          (n) => n.total_count
        );
        this.series[1].data = response.data.month_trend.map(
          (n) => n.total_viewers
        );
        this.chartOptions.xaxis.categories = response.data.month_trend.map(
          (n) => this.getFormatedDate(n.date)
        );
        this.key += 1;
      } catch (err) {
        console.log(err);
      }
    },
  },
  mounted() {
    this.getDayTrend();
  },
};
</script>
