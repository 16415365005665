<template>
  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>Partner</label>
        <multiselect
          v-model="filters.partner_ids"
          :options="partner_ids"
          :close-on-select="false"
          :show-labels="false"
          track-by="name"
          label="name"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Partner Divisions</label>
        <multiselect
          v-model="filters.partner_division_ids"
          :options="partner_division_ids"
          :close-on-select="false"
          :show-labels="false"
          track-by="title"
          label="title"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Speciality</label>
        <multiselect
          v-model="filters.speciality"
          :options="specialities"
          :close-on-select="false"
          :show-labels="false"
          track-by="id"
          label="title"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>User Type</label>
        <multiselect
          v-model="filters.userType"
          :options="userType"
          :close-on-select="false"
          :show-labels="false"
          :multiple="true"
        ></multiselect>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group mb-3">
        <label>Date Range</label>
        <date-picker
          v-model="filters.daterange"
          range
          append-to-body
          lang="en"
          confirm
        ></date-picker>
      </div>
    </div>
    <div 
      class="d-flex align-items-center justify-content-end" 
      :class="{'col-md-2': clicked,'col-md-2 justify-content-end': !clicked,}"
    >
      <div
        :class="{ '': clicked, 'mb-2': !clicked }"
        style="text-decoration: underline; font-size: 16px; cursor: pointer"
        @click="added"
      >
        {{ clicked ? "Less Filters" : "More Filters" }}
      </div>
    </div>
    <template v-if="isHidden">
      <div class="col-md-2">
        <div class="form-group">
          <label>Country</label>
          <multiselect
            v-model="filters.countries"
            :options="countries"
            :close-on-select="false"
            :show-labels="false"
            track-by="name"
            label="name"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Zone</label>
          <multiselect
            v-model="filters.zones"
            :options="zones"
            :close-on-select="false"
            :show-labels="false"
            track-by="name"
            label="name"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>State</label>
          <multiselect
            v-model="filters.state"
            :options="states"
            :close-on-select="false"
            :show-labels="false"
            track-by="name"
            label="name"
            :multiple="true"
          ></multiselect>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>City</label>
          <multiselect
            v-model="filters.city"
            :options="cities"
            :close-on-select="false"
            :show-labels="false"
            track-by="name"
            label="name"
            :multiple="true">
          </multiselect>
        </div>
      </div>
    </template>
  </div>
  <!-- end row -->
</template>

<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import appConfig from "@/app.config";
import moment from "moment";

export default {
  //   props : ['projects'],
  components: {
    Multiselect,
    DatePicker,
  },
  data() {
    return {
      filters: {
        startDate: null,
        endDate: null,
        countries: null,
        zones: null,
        state: null,
        city: null,
        partner_ids: null,
        partner_division_ids: null,
        speciality: null,
        userType: null,
        opted_in: 0,
        daterange: null,
      },
      partner_ids: [],
      partner_division_ids: [],
      countries: [],
      zones: [],
      states: [],
      cities: [],
      specialities: [],
      userType: [],
      isHidden: false,
      clicked: false,
    };
  },
  methods: {
    added() {
      this.clicked = !this.clicked;
      this.isHidden = !this.isHidden;
    },
    async getPartners() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/partners"
        );
        this.partner_ids = response.data.partners;
      } catch (err) {
        console.log(err);
      }
    },
    async getPartnerDivisions(partner_ids) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/partners/divisions",
          {
            params: {
              partner_ids: partner_ids.map((obj) => obj.id),
            },
          }
        );
        this.partner_division_ids = response.data.partner_divisions;
      } catch (err) {
        console.log(err);
      }
    },
    async getCountries() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/countries"
        );
        this.countries = response.data.countries;
      } catch (err) {
        console.log(err);
      }
    },
    async getZones(countries) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/zones",
          {
            params: {
              countries: countries.map((obj) => obj.name),
            },
          }
        );
        this.zones = response.data.zones;
      } catch (err) {
        console.log(err);
      }
    },
    async getStates(zones) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/states",
          {
            params: {
              zones: zones.map((obj) => obj.name),
            },
          }
        );

        this.states = response.data.states;
      } catch (err) {
        console.log(err);
      }
    },
    async getCities(states) {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/geo/cities",
          {
            params: {
              states: states.map((obj) => obj.name),
            },
          }
        );
        this.cities = response.data.cities;
      } catch (err) {
        console.log(err);
      }
    },
    async getSpecialities() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/specialities"
        );
        this.specialities = response.data.specialities;
      } catch (err) {
        console.log(err);
      }
    },
    async getUserType() {
      try {
        const response = await this.$http.get(
          appConfig.api_base_url + "/users/member-types"
        );
        this.userType = response.data.member_types;
      } catch (err) {
        console.log(err);
      }
    },
    getFormatedDate() {
      try {
        this.filters.daterange = moment(this.filters.daterange).format(
          "yyyy-mm-dd"
        );
      } catch (err) {
        console.log(err);
        return null;
      }
    },
    async loadFilters() {
      this.getPartners();
      this.getSpecialities();
      this.getCountries();
      this.getUserType();
      // this.getStates(this.countries);
    },
  },
  mounted() {
    this.loadFilters();
  },
  watch: {
    filters: {
      deep: true,
      handler(v) {
        this.$emit("filters", v);
      },
    },
    "filters.countries"(v) {
      this.getZones(v);
    },
    "filters.zones"(v) {
      this.getStates(v);
    },
    "filters.state"(v) {
      this.getCities(v);
    },
    "filters.partner_ids"(v) {
      this.getPartnerDivisions(v);
    },
  },
};
</script>
